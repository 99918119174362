
const CONSTANTS = {
  ENV_QUALIFIER: 'stg',
  AUTH_PROVIDER: '__AUTH_PROVIDER__',
  AUTH: {
    DOMAIN: 'auth-stg.kenai.co.za',
    CLIENTID: 'FFdZBd6UH67EhroaqQgoddKZnDa1Bjlo',
    ISS: 'https://auth-stg.kenai.co.za/',
    AUD: 'FFdZBd6UH67EhroaqQgoddKZnDa1Bjlo',
  },
  AADB2CAUTH: {
    DOMAIN: 'loginstg.kenai.co.za',
    CLIENTID_ACCESS: '21581865-f822-4bf9-b2f2-5718b9b8af60',
    CLIENTID_AUTHZ: '0dc2c43b-3d45-4a5d-b6bc-6880345bed42',
    ISS: 'https://loginstg.kenai.co.za/5a58f0d1-d8e1-4be6-88e3-5c7a12092160/v2.0/',
    AUD: '0dc2c43b-3d45-4a5d-b6bc-6880345bed42',
    POLICY_ACCESS: 'B2C_1A_KenaiWCL',
    POLICY_AUTHZ: 'B2C_1A_KenaiATZ',
    TENANT: 'kenaib2cstaging.onmicrosoft.com',
    SCOPE_ACCESS: 'https://loginstg.kenai.co.za/kenai/access/token',
    SCOPE_AUTHZ: 'https://loginstg.kenai.co.za/kenai/authz/token',
    AUTO_ACTIVATE_DOMAINS: '__ADB2C_AUTO_ACTIVATE_DOMAINS__'
  },
  ANALYTICS: {
    HEAP_APP_ID: '2236542519',
    HOTJAR_APP_ID: '954850'
  },
}

export default CONSTANTS
